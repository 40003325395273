import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Please come and join us at the Ville for a `}<strong parentName="p">{`FREE`}</strong>{` Labor Day WOD at
8:30 am…get it out of the way early and enjoy the rest of your
Holiday!`}</p>
    <p>{`Partner WOD: (Bring a New Crossfit Friend, an Old Crossfit Pal or just
show up and workout with someone new)`}</p>
    <p><strong parentName="p">{`“LABOR”`}</strong></p>
    <p>{`*`}{`3 Burpees on the minute every minute for everyone not working (Yes,
even when not running)`}</p>
    <p>{`1 Mile Run (Total Distance) 1 team member at a time`}</p>
    <p>{`100 Box Jumps (24/20/18)`}</p>
    <p>{`100 Deadlifts (185/135/95)`}</p>
    <p>{`100 Ring Rows`}</p>
    <p>{`100 Thrusters (95/65/45)`}</p>
    <p>{`1 Mile Run (Total Distance) 1 team member at a time`}</p>
    <p>{`*`}{`REMEMBER these workouts can be scaled to your fitness level so do not
let the numbers scare you…`}</p>
    <p>{`’`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      